import { RecordedData } from "../types";
import dayjs from "dayjs";
import { encodeWav } from "./wav";
import { APP_NAME } from "../environments";

export function generateVoiceFileData(
  allRecordedData: RecordedData[]
): FormData {
  const formData = new FormData();
  const now = dayjs().format("YYYY.MM.DD.HH.mm.ss");
  for (
    let phraseIndex = 0;
    phraseIndex < allRecordedData.length;
    ++phraseIndex
  ) {
    const recorded = allRecordedData[phraseIndex];
    if (recorded.isSkipped) continue;

    const phrase = phraseIndex.toString().padStart(2, "0");
    // NOTE:アップロードされたファイルがあればそれを採用する（ファイル解析用）
    if (recorded.file) {
      formData.append("wav_files", recorded.file);
    } else {
      const wav = encodeWav(recorded.data, recorded.samplingRate);
      formData.append("wav_files", wav, `${phrase}_${now}.wav`);
    }

    // 音声タイプ関連をまとめて管理
    const voiceTypesMap = {
      cog_voice_types: recorded.co1VoiceType,
      dysphagia_voice_types: recorded.dysphagiaVoiceTypes,
      br1_voice_types: recorded.br1VoiceType,
      sl1_voice_types: recorded.sl1VoiceType,
      co2_voice_types: recorded.co2VoiceType,
    };

    const containsVoiceTypes = Object.entries(voiceTypesMap).filter(
      ([, value]) => {
        // 値なしをフィルタ
        return value != undefined;
      }
    );

    // voiceTypeを含むものだけformDataに追加
    containsVoiceTypes.forEach(([voiceType, value]) => {
      formData.append(voiceType, value as string);
    });

    if (recorded.phrase) {
      formData.append("phrases", recorded.phrase);
    }
  }
  formData.append(
    "app_version",
    `${APP_NAME.replaceAll(" ", "_")}-${__GIT_DESCRIBE_REVISION__}`
  );
  return formData;
}
