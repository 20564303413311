import { ReactElement, createElement } from "react";
import { Box, Flex, Stack, Text, Progress, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import br1Image from "../../assets/images/uix/br1.png";
import {
  AnalysisResultDurationPath,
  AnalysisResult as Br1Result,
  ConvertResult as ConvertBr1Result,
} from "../../engines/br1Engine";
import {
  MeanResult,
  ResultDiagramIndex,
  ResultDiagramParam,
} from "../../types";
import allPhrases from "../../assets/json/phrases.json";
import {
  RenderMainResult,
  RenderUixTable,
  getPhraseNumberByFilename,
} from "./ResultUIX";
import { findMeanByDataPath } from "../../engines/baseAnalysisEngine";

function RenderMainContent({
  result,
  average,
}: Readonly<{
  result: Record<string, number | string>;
  average: MeanResult;
}>): ReactElement {
  const { t } = useTranslation();
  const progressMax = 98; // 100だと目盛と少しずれる
  const duration: number | string = result["LVP_DR"];
  const durationMax = 15;
  const durationAverage = findMeanByDataPath(
    average,
    AnalysisResultDurationPath
  );
  const durationAveragePercentage = durationAverage
    ? Math.min((durationAverage / durationMax) * progressMax, progressMax)
    : null;
  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize={{ base: "sm", sm: "xl" }}>
          {t("UixResult.Br1.title")}
        </Text>
        <Text fontSize={{ base: "sm", sm: "lg" }}>{`${t(
          "UixResult.Br1.long_vowel_duration"
        )} ${duration}${t("UixResult.voice_length_sec")}`}</Text>
      </Flex>
      <Flex alignItems="flex-end" mt={1} justifyContent="space-between">
        <Text mt="2" mr="4" whiteSpace="nowrap" fontSize="sm" mb="4">
          {t("UixResult.Br1.duration")}
        </Text>
        <VStack
          align="stretch"
          width="85%"
          height="50px"
          style={{ position: "relative" }}
        >
          <Flex justifyContent="space-between" mb="-2">
            <Text fontSize="xs">0</Text>
            <Text fontSize="xs">5</Text>
            <Text fontSize="xs">10</Text>
            <Text fontSize="xs">15</Text>
          </Flex>
          <Progress
            value={typeof duration === "number" ? duration : 0}
            max={durationMax}
            width={`${progressMax}%`}
          />
          {durationAveragePercentage && (
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: `calc(${durationAveragePercentage}%)`,
                transform: "translateX(-50%)",
              }}
            >
              <Text fontSize="sm" color="primary.theme_lv2">
                ▲
              </Text>
            </div>
          )}
        </VStack>
      </Flex>
    </Box>
  );
}

export function RenderResult({
  br1Result,
  br1Average,
}: Readonly<{
  br1Result: Br1Result;
  br1Average: MeanResult;
}>): ReactElement {
  const { t } = useTranslation();

  const convertBr1Result = ConvertBr1Result({ result: br1Result });

  const tableParamColumns: ResultDiagramIndex[] = [
    {
      width: "100%",
      title: t("UixResult.voice_analysis_details"),
      align: "left",
    },
    {
      width: "100px",
      subWidth: "60px",
      title: t("UixResult.voice_analysis_score"),
      align: "center",
    },
  ];

  const tableParamData: ResultDiagramParam[][] = [
    [
      { value: t("Result.BNP_Score"), align: "left" },
      { value: convertBr1Result["BNP_score"] },
    ],
    [
      { value: t("Result.GOKAN_Score"), align: "left" },
      { value: convertBr1Result["GOKAN_score"] },
    ],
    [
      { value: t("UixResult.Br1.NYHA_prob"), align: "left" },
      { value: convertBr1Result["NYHA_prob"] },
    ],
    [
      { value: t("UixResult.Br1.CPA_prob"), align: "left" },
      { value: convertBr1Result["CPA_prob"] },
    ],
    [
      { value: t("UixResult.Br1.CON_prob"), align: "left" },
      { value: convertBr1Result["CON_prob"] },
    ],
  ];

  const tablePhrasesColumns: ResultDiagramIndex[] = [
    { width: "32px", title: "No" },
    { width: "100%", title: t("UixResult.voice_content"), align: "left" },
    {
      width: "100px",
      subWidth: "60px",
      title: t("UixResult.Br1.voice_strength"),
    },
    { width: "100px", subWidth: "60px", title: "sec" },
  ];

  const br1SubParameters: Record<string, object> = br1Result
    ? br1Result.br1_sub_parameters
    : {};
  const tablePhrasesData: ResultDiagramParam[][] = [];
  const filenames = Object.keys(br1SubParameters);
  const sortedFilenames = filenames.sort();
  const uixPhrases = allPhrases["UIX"]["phrases"];

  sortedFilenames.forEach((filename) => {
    const phraseIndex = getPhraseNumberByFilename(filename);
    const uixPhrase = uixPhrases[phraseIndex];
    if ("br1VoiceType" in uixPhrase && uixPhrase.br1VoiceType != "NO_SETTING") {
      const phrase =
        uixPhrases[phraseIndex]["resultPhraseKey"] ??
        uixPhrases[phraseIndex]["phraseKey"];
      const dataList: ResultDiagramParam[] = [
        { value: phraseIndex + 1 },
        { value: t(phrase), align: "left" },
      ];
      if (uixPhrase.br1VoiceType === "REPEAT_PATAKA") {
        dataList.push(
          { value: convertBr1Result["DSTP_PA"] },
          { value: convertBr1Result["DSTP_DR"] }
        );
      } else if (uixPhrase.br1VoiceType === "LONG_LONG_VOWEL_A") {
        dataList.push(
          { value: convertBr1Result["LVP_RM"] },
          { value: convertBr1Result["LVP_DR"] }
        );
      }
      tablePhrasesData.push(dataList);
    }
  });

  return (
    <Stack spacing={2}>
      <RenderMainResult
        imgSrc={br1Image}
        content={createElement(RenderMainContent, {
          result: convertBr1Result,
          average: br1Average,
        })}
      />
      <RenderUixTable
        diagramIndex={tableParamColumns}
        diagramParams={tableParamData}
      />
      <RenderUixTable
        diagramIndex={tablePhrasesColumns}
        diagramParams={tablePhrasesData}
      />
    </Stack>
  );
}
