import { Box, Text, useTheme } from "@chakra-ui/react";
import { ReactElement, useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { LoginErrorCode, LoginFormData } from "../../types";
import { useGetAccessToken } from "../../utils/token";
import { useNavigate } from "react-router-dom";
import { useLoginProcess } from "../../hooks/useLoginProcess";
import { AUTOLOGIN_PASS, AUTOLOGIN_USER } from "../../environments";
import { useTranslation } from "react-i18next";

export function AutoLogin(props: Readonly<{
  onInvalidUserInfo: (errorCode: LoginErrorCode) => void;
}>): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const strokeColor = theme.colors.primary["theme_lv1"];
  const getAccessToken = useGetAccessToken();
  const navigate = useNavigate();
  const loginProcess = useLoginProcess(props.onInvalidUserInfo);
  const [loginProcessCompleted, setLoginProcessCompleted] = useState(false);
  const [isLoginError, setIsLoginError] = useState(false);

  useEffect(() => {
    if (!loginProcessCompleted) {
      const autoLoginUser: LoginFormData = {
        user_id: AUTOLOGIN_USER,
        password: AUTOLOGIN_PASS,
      };
      getAccessToken(props.onInvalidUserInfo, autoLoginUser).then((token) => {
        if (token) {
          loginProcess(token, AUTOLOGIN_USER.split(",").pop());
        } else {
          setIsLoginError(true);
        }
        setLoginProcessCompleted(true);
      });
    }
  }, [
    getAccessToken,
    props.onInvalidUserInfo,
    navigate,
    loginProcess,
    loginProcessCompleted,
  ]);

  return (
    <Box
      h="100vh"
      w="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bg="primary.bg_lv1"
    >
      <>
        {isLoginError && (
          <Box h="100%" w="100%" padding={5}>
            <Text whiteSpace="pre-wrap">{t("AutoLogin.Error")}</Text>
          </Box>
        )}
        {!isLoginError && (
          <RotatingLines width="100" strokeColor={strokeColor} />
        )}
      </>
    </Box>
  );
}
