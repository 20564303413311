import { jwtDecode, JwtPayload } from "jwt-decode";
import { ApiEngineType } from "../types";

export type EngineTypesParams = {
  engine_type: string;
  options: Record<string, boolean>;
};

export type DecodedAccessToken = {
  userId: string;
  userType: string;
  engineTypes?: Record<ApiEngineType | "all", EngineTypesParams>;
  companyId?: string;
  salesagentId?: string;
  companyEmail?: string;
  employeeEmail?: string;
  recType?: number;
} & JwtPayload;

export const decodeJwt = (token: string): DecodedAccessToken => {
  return jwtDecode<DecodedAccessToken>(token);
};
