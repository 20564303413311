import { ReactElement } from "react";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { IoChevronDown, IoGlobeOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

type Props = {
  to: string;
};

export function LanguageSelector(props: Readonly<Props>): ReactElement {
  return (
    <Menu>
      <MenuButton
        as={Button}
        minWidth={40}
        maxWidth={40}
        margin="10px auto"
        leftIcon={<IoGlobeOutline />}
        variant="ghost"
        borderRadius={0}
        border="1px solid black"
        bg="common.base"
        _hover={{ backgroundColor: "primary.bg_lv1" }}
        _active={{ backgroundColor: "primary.bg_lv1" }}
      >
        <Flex alignItems="center" columnGap={2}>
          Language
          <IoChevronDown />
        </Flex>
      </MenuButton>
      <MenuList minWidth={40} borderRadius={0} backgroundColor="common.base">
        <MenuItem
          as={Link}
          to={`/ja/${props.to}`}
          color="text.subtext"
          _focus={{ backgroundColor: "primary.bg_lv1" }}
        >
          日本語
        </MenuItem>
        <MenuItem
          as={Link}
          to={`/en/${props.to}`}
          color="text.subtext"
          _focus={{ backgroundColor: "primary.bg_lv1" }}
        >
          English
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
