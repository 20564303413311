import { createContext, ReactNode, useState, useCallback } from "react";

interface ImageCategory {
  images: Record<string, string>;
  WeatherImg: Record<string, string>;
  TfhImg: Record<string, string>;
}

interface ImageContextProps {
  imageSrc: ImageCategory;
  isLoading: boolean;
  preloadImage: (
    category: keyof ImageCategory,
    urls: Record<string, string>
  ) => void;
}

export const ImageContext = createContext<Partial<ImageContextProps>>({});

interface ImageProviderProps {
  children: ReactNode;
}

// eslint-disable-next-line react/prop-types
export const ImageProvider: React.FC<ImageProviderProps> = ({ children }) => {
  const [imageSrc, setImageSrc] = useState<ImageCategory>({
    images: {},
    WeatherImg: {},
    TfhImg: {},
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const preloadImage = useCallback(
    (category: keyof ImageCategory, urls: Record<string, string>) => {
      let loadedImages = 0;
      const totalImages = Object.keys(urls).length;

      const onLoadImage = (key: string, url: string): void => {
        setImageSrc((prevState) => ({
          ...prevState,
          [category]: {
            ...prevState[category],
            [key]: url,
          },
        }));
        loadedImages++;
        if (loadedImages === totalImages) {
          setIsLoading(false);
        }
      };

      const onLoadError = (): void => {
        loadedImages++;
        if (loadedImages === totalImages) {
          setIsLoading(false);
        }
      };

      setIsLoading(true);
      Object.entries(urls).forEach(([key, url]) => {
        const image = new Image();
        image.onload = () => onLoadImage(key, url);
        image.onerror = () => onLoadError();
        image.src = url;
      });
    },
    []
  );

  const value = {
    imageSrc,
    isLoading,
    preloadImage,
  };

  return (
    <ImageContext.Provider value={value}>{children}</ImageContext.Provider>
  );
};
