import { ReactElement } from "react";
import { Avatar, HStack, Text, VStack, useTheme } from "@chakra-ui/react";
import { SelectionLoginUser } from "../../types";

type Props = {
  user: SelectionLoginUser;
  disabled?: boolean;
  onClick?: () => void;
};

export function SelectionLoginUserButton(props: Readonly<Props>): ReactElement {
  const theme = useTheme();
  const boxShadowColor = theme.colors.login["login_button_bg"];
  return (
    <HStack
      as="button"
      type="button"
      width="full"
      px={{ base: 3, md: 5 }}
      py={5}
      boxShadow={`0 3px 10px ${boxShadowColor}`}
      rounded={20}
      background="white"
      color="common_text.dark"
      _active={{ bgColor: "white" }}
      _focus={{ bgColor: "white" }}
      _disabled={{ bgColor: "login.login_button_bg", cursor: "not-allowed" }}
      sx={{
        "@media (hover: hover)": {
          "&:hover:not([disabled])": { bgColor: "login.login_button_bg" },
        },
      }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <Avatar backgroundColor={props.user.color} size="md" />
      <VStack align="flex-start">
        <Text as="small" lineHeight={1} wordBreak="break-all" textAlign="left">
          {props.user.email}
        </Text>
        <Text as="span" lineHeight={1} wordBreak="break-all" textAlign="left">
          {props.user.nickname}
        </Text>
      </VStack>
    </HStack>
  );
}
