import { ReactElement } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Stack,
  useTheme,
  Flex,
  Box,
  Image,
} from "@chakra-ui/react";
import { AnalysisResult as Br1Result } from "../../engines/br1Engine";
import { AnalysisResult as Co1Result } from "../../engines/co1Engine";
import { AnalysisResult as Mi1Result } from "../../engines/mi1Engine";
import { AnalysisResult as Mi2Result } from "../../engines/mi2Engine";
import {
  MeanResult,
  ResultDiagramIndex,
  ResultDiagramParam,
  TableAlign,
} from "../../types";
import { RenderResult as RenderBr1Result } from "./ResultUIX_Br1";
import { RenderResult as RenderCo1Result } from "./ResultUIX_Co1";
import { RenderResult as RenderMi1Mi2Result } from "./ResultUIX_Mi1_Mi2";
import { APP_TYPE } from "../../environments";

export type UixResultType = Br1Result | Mi1Result | Mi2Result | Co1Result;

// FIXME: ファイル名の命名規則が変わった時に対応出来ないので、voice_profilesからフレーズNoを取り出す等の対策が必要
// ファイル名：[PhraseNo]_2023.09.11.18.00.00.wavからPhraseNoを取り出す
export const getPhraseNumberByFilename = (filename: string): number => {
  const parts = filename.split("_");
  if (parts.length > 0) {
    const firstPart = parts[0];
    return parseInt(firstPart, 10);
  }
  return 99;
};

function CustomTh({
  width,
  subWidth,
  title,
  textAlign,
}: Readonly<{
  key: number;
  width: string;
  subWidth?: string;
  title: string;
  textAlign?: TableAlign;
}>): ReactElement {
  const effectiveAlign = textAlign || "center";
  return (
    <Th
      width={{ base: subWidth ?? width, sm: width }}
      fontSize="sm"
      borderWidth="1px"
      borderColor="black"
      paddingLeft={effectiveAlign === "left" ? 2 : 0}
      paddingY={1}
      textAlign={effectiveAlign}
      color={APP_TYPE === "uix" ? "black" : "white"}
      textTransform="none"
      style={
        effectiveAlign === "center"
          ? { paddingInlineStart: 0, paddingInlineEnd: 0 }
          : {}
      }
    >
      {title}
    </Th>
  );
}

function CustomTd({
  value,
  textAlign,
  backgroundColor,
}: Readonly<{
  value: number | string;
  textAlign?: TableAlign;
  backgroundColor?: string;
}>): ReactElement {
  const effectiveAlign = textAlign || "center";
  return (
    <Td
      fontSize="sm"
      borderWidth="1px"
      borderColor="black"
      paddingY={1}
      textAlign={effectiveAlign}
      paddingX={0}
      paddingInlineStart={{
        base: effectiveAlign === "left" ? 2 : 0,
        sm: effectiveAlign === "left" ? 15 : 0,
      }}
      backgroundColor={backgroundColor ?? undefined}
    >
      {value}
    </Td>
  );
}

export function RenderMainResult({
  imgSrc,
  content,
}: Readonly<{
  imgSrc: string;
  content: ReactElement;
}>): ReactElement {
  const theme = useTheme();
  const borderColor = theme.colors.primary["theme_lv1"];
  return (
    <Flex
      direction="row"
      borderTop={`2px solid ${borderColor}`}
      borderBottom={`2px solid ${borderColor}`}
      bg="white"
      padding={2}
      alignItems="center"
      height="110px"
    >
      <Box width="70px" mr={2}>
        <Image src={imgSrc} alt="image" objectFit="cover" />
      </Box>
      <Flex direction="column" width="100%">
        {content}
      </Flex>
    </Flex>
  );
}

export function RenderUixTable({
  diagramIndex,
  diagramParams,
}: Readonly<{
  diagramIndex: ResultDiagramIndex[];
  diagramParams: ResultDiagramParam[][];
}>): ReactElement {
  const theme = useTheme();
  const backgroundColor =
    APP_TYPE === "uix" ? "#DCDCDC" : theme.colors.primary["theme_lv1"];
  return (
    <Table style={{ tableLayout: "fixed" }}>
      <Thead bg={backgroundColor}>
        <Tr>
          {diagramIndex &&
            diagramIndex.map((value, index) => (
              <CustomTh
                key={index}
                width={value.width}
                subWidth={value.subWidth}
                title={value.title}
                textAlign={value.align}
              />
            ))}
        </Tr>
      </Thead>
      <Tbody bg="white">
        {diagramParams &&
          diagramParams.map((value, index) => (
            <Tr key={index}>
              {value &&
                value.map((value2, index2) => (
                  <CustomTd
                    key={index2}
                    value={value2.value}
                    textAlign={value2.align}
                    backgroundColor={value2.backgroundColor}
                  />
                ))}
            </Tr>
          ))}
      </Tbody>
    </Table>
  );
}

export function ResultUIX({
  resultsMap,
  averageMap,
}: Readonly<{
  resultsMap: Map<string, UixResultType>;
  averageMap: Map<string, MeanResult>;
}>): ReactElement {
  const br1Result = resultsMap.get("br1") as Br1Result;
  const co1Result = resultsMap.get("co1") as Co1Result;
  const mi1Result = resultsMap.get("mi1") as Mi1Result;
  const mi2Result = resultsMap.get("mi2") as Mi2Result;
  const br1Average = averageMap.get("br1") as MeanResult;
  const co1Average = averageMap.get("co1") as MeanResult;

  return (
    <Stack spacing={4} mb={4}>
      <RenderBr1Result br1Result={br1Result} br1Average={br1Average} />
      <RenderMi1Mi2Result mi1Result={mi1Result} mi2Result={mi2Result} />
      <RenderCo1Result co1Result={co1Result} co1Average={co1Average} />
    </Stack>
  );
}
