import { Td } from "@chakra-ui/react";
import { ReactElement } from "react";
import { TableAlign } from "../../types";

export function TFHCustomTd({
  value,
  textAlign,
  width,
}: Readonly<{
  value: number | string;
  textAlign?: TableAlign;
  width?: string;
}>): ReactElement {
  const effectiveAlign = textAlign || "center";
  return (
    <Td
      width={width}
      borderWidth="1px"
      borderColor="black"
      paddingY={1}
      textAlign={effectiveAlign}
      paddingX={0}
      paddingInlineStart={{
        base: effectiveAlign === "left" ? 2 : 0,
        sm: effectiveAlign === "left" ? 15 : 0,
      }}
    >
      {value}
    </Td>
  );
}
