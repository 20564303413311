import {
  AnalysisEngineType,
  AnalysisErrorReason,
  AnalysisState,
  RecordedData,
} from "../types";
import { detectVolumeAtom, QuestionnaireAnswers } from "../store";
import { MutableRefObject, useCallback } from "react";
import { useGetAnalysisEngines } from "../utils/selectAnalysisEngine";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { appAxios } from "../utils/appAxios";
import { SKIP_QUESTIONNAIRES, USE_ANALYSIS_ERROR_PAGE } from "../environments";
import { useToastMessage } from "./useToastMessage";
import axios from "axios";
import { generateVoiceFileData } from "../utils/generateVoiceFileData";
import { useTranslation } from "react-i18next";

type HandleApiError = (err: unknown) => void;

function useHandleApiError(): HandleApiError {
  const navigate = useNavigate();
  const toastMessage = useToastMessage();
  return useCallback(
    (err: unknown): void => {
      if (
        USE_ANALYSIS_ERROR_PAGE &&
        axios.isAxiosError(err) &&
        err.code &&
        err.message
      ) {
        const state: AnalysisErrorReason = {
          code: err.code,
          message: err.message,
        };
        navigate(`../analysis-error`, { state });
      } else {
        toastMessage(err);
      }
    },
    [navigate, toastMessage]
  );
}

function randomUnsafeStr(): string {
  // eslint-disable-next-line sonarjs/pseudo-random
  return Math.random().toString(32).substring(2);
}

type AnalyzeVoice = (
  recordedData: RecordedData[],
  answers: QuestionnaireAnswers,
  requestIdRef: MutableRefObject<null | string>,
  setAnalysisStatus: (state: AnalysisState) => void
) => Promise<void>;

export function useAnalyzeVoice(engineType: AnalysisEngineType): AnalyzeVoice {
  const handleApiError = useHandleApiError();
  const analysisEngines = useGetAnalysisEngines(engineType);
  const [detectVolume] = useAtom(detectVolumeAtom);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toastWarning = useToastMessage();

  return useCallback(
    async (recordedData, answers, requestIdRef, setAnalysisStatus) => {
      setAnalysisStatus("UPLOADING");
      const requestId = randomUnsafeStr();
      requestIdRef.current = requestId;
      let analysisFileId = "";

      try {
        // ファイルアップロード
        const formData = generateVoiceFileData(recordedData);
        const uploadingResponse = await appAxios.post("/voice/file", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        analysisFileId = uploadingResponse.data.file_id;
        // アンケート送信
        if (!SKIP_QUESTIONNAIRES) {
          await appAxios.post("/questionnaire", {
            file_id: analysisFileId,
            questionnaire_name: answers.name,
            questionnaire_content: answers.answers,
          });
        }

        // 音声解析
        const requests = analysisEngines.map((engine) =>
          engine.analyze(analysisFileId, detectVolume)
        );

        setAnalysisStatus("ANALYZING");
        const analysisResponse = await Promise.allSettled(requests);

        // 解析結果確認
        const isAllFailed = analysisResponse.every(
          (result) => result.status === "rejected"
        );
        if (isAllFailed) {
          handleApiError((analysisResponse[0] as PromiseRejectedResult).reason);
        } else if (requestIdRef.current === requestId) {
          navigate(`../results/${analysisFileId}`);
        }
      } catch (err) {
        // アンケート文の重複エラーはトーストのみ
        if (
          analysisFileId &&
          axios.isAxiosError(err) &&
          err.config?.url === "/questionnaire" &&
          err.response?.status === 409
        ) {
          toastWarning(t("Error.Questionnaire409Error"), "warning");
          navigate(`../results/${analysisFileId}`);
        } else {
          handleApiError(err);
        }
      } finally {
        setAnalysisStatus("IDLE");
      }
    },
    [analysisEngines, detectVolume, handleApiError, navigate, t, toastWarning]
  );
}
