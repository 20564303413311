import { ReactElement, useContext, useEffect, useState } from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { ImageContext } from "../atoms/ImageProvider";
import { useTranslation } from "react-i18next";
import WeatherSunnyImgUrl from "../../assets/images/weather/sunny.png";
import WeatherCloudyImgUrl from "../../assets/images/weather/cloudy.png";
import WeatherRainyImgUrl from "../../assets/images/weather/rainy.png";
import { ResultDashboardData, ResultSubParam } from "../../types";

function formatScore(score: string): string {
  return score.replace("(Posi)", "").replace("(Nega)", "");
}

export function ResultMi2Demo({
  result,
}: Readonly<{
  result: ResultDashboardData;
}>): ReactElement {
  const { imageSrc, preloadImage } = useContext(ImageContext);
  const [resultComment, setResultComment] = useState("");
  const { t } = useTranslation();

  const findSubParam = (title: string): ResultSubParam | undefined => {
    return result.subParams.find(
      (param) => param.title.toLowerCase() === title.toLowerCase()
    );
  };

  const cpfParam = findSubParam("Result.Mi2_cpf");
  const zhParam = findSubParam("Result.Mi2_zh");

  const mi12Score = result.mainParam.value;
  const cpfScore = cpfParam && cpfParam.value ? cpfParam.value.toString() : "-";
  const cpfColor = cpfParam && cpfParam.color ? cpfParam.color : "black";
  const zhScore = zhParam && zhParam.value ? zhParam.value.toString() : "-";
  const zhColor = zhParam && zhParam.color ? zhParam.color : "black";

  useEffect(() => {
    if (mi12Score && imageSrc && !imageSrc.WeatherImg[mi12Score]) {
      preloadImage &&
        preloadImage("WeatherImg", {
          A: WeatherSunnyImgUrl,
          B: WeatherCloudyImgUrl,
          C: WeatherRainyImgUrl,
        });
    }
  }, [imageSrc, mi12Score, preloadImage, result]);

  useEffect(() => {
    if (mi12Score) {
      switch (mi12Score) {
        case "A":
          setResultComment(t("Mi2DemoResult.resultA_comment"));
          break;
        case "B":
          setResultComment(t("Mi2DemoResult.resultB_comment"));
          break;
        case "C":
          setResultComment(t("Mi2DemoResult.resultC_comment"));
          break;
        default:
          setResultComment(""); // 未知の結果の場合
      }
    }
  }, [mi12Score, result, t]);

  return (
    <Box mb={10} color="#555555">
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Text fontSize="2xl">{resultComment}</Text>
        {mi12Score && (
          <Image
            src={imageSrc ? imageSrc.WeatherImg[mi12Score] : undefined}
            boxSize="80%"
          />
        )}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="80%"
        >
          <Flex width="100%" justifyContent="center" mb={2}>
            <Text
              fontSize="md"
              width="100px"
              textAlign="center"
              bg="gray.100"
              borderRadius="md"
              mr={2}
            >
              CpF
            </Text>
            <Text fontSize="md" width="30px" textAlign="left" color={cpfColor}>
              {formatScore(cpfScore)}
            </Text>
          </Flex>
          <Flex width="100%" justifyContent="center">
            <Text
              fontSize="md"
              width="100px"
              textAlign="center"
              bg="gray.100"
              borderRadius="md"
              mr={2}
            >
              Z-H
            </Text>
            <Text fontSize="md" width="30px" textAlign="left" color={zhColor}>
              {formatScore(zhScore)}
            </Text>
          </Flex>
        </Box>
        <Box width="80%" mt={5}>
          <Text fontSize="sm">{t("Mi2DemoResult.note1")}</Text>
          <Text fontSize="sm" mt={2}>
            {t("Mi2DemoResult.note2")}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
}
