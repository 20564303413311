import { ReactElement, createElement } from "react";
import {
  Box,
  Flex,
  Stack,
  Text,
  Progress,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import mi2mi2Image from "../../assets/images/uix/mi1_mi2.png";
import { AnalysisResult as Mi1Result } from "../../engines/mi1Engine";
import {
  AnalysisResult as Mi2Result,
  ConvertResult as ConvertMi2Result,
} from "../../engines/mi2Engine";
import { ResultDiagramIndex, ResultDiagramParam } from "../../types";
import {
  RenderMainResult,
  RenderUixTable,
  getPhraseNumberByFilename,
} from "./ResultUIX";
import { RenderRadarChart } from "../atoms/RadarChart";
import { ChartData, ChartOptions } from "chart.js";
import { hexToRgba } from "../../utils/colorUtil";
import allPhrases from "../../assets/json/phrases.json";

type VOICE_DATA = {
  wav_filename: string;
  start_time: number;
  end_time: number;
};

function RenderMainContent({
  result,
}: Readonly<{
  result: Record<string, number | string>;
}>): ReactElement {
  const { t } = useTranslation();
  const progressMax = 98; // 100だと目盛と少しずれる
  const ZH_Score = result["zh_score"];
  const CpF_Score = result["cpf_score"];
  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize={{ base: "sm", sm: "xl" }}>
          {t("UixResult.Mi1Mi2.title")}
        </Text>
        <Text fontSize={{ base: "sm", sm: "lg" }}>
          <span style={{ marginRight: "20px" }}>{`Z-H : ${ZH_Score}`}</span>
          {`CpF : ${CpF_Score}`}
        </Text>
      </Flex>
      <Flex alignItems="flex-end" mt={1} justifyContent="space-between">
        <Text mt="2" whiteSpace="nowrap" fontSize="sm" mb="-1">
          Z-H
        </Text>
        <VStack align="stretch" width="85%">
          <Flex justifyContent="space-between" mb="-2">
            <Text fontSize="xs">0</Text>
            <Text fontSize="xs">50</Text>
            <Text fontSize="xs">100</Text>
          </Flex>
          <Progress
            value={typeof ZH_Score === "number" ? ZH_Score : 0}
            max={100}
            width={`${progressMax}%`}
          />
        </VStack>
      </Flex>
      <Flex alignItems="flex-end" mt={-1} justifyContent="space-between">
        <Text mt="2" whiteSpace="nowrap" fontSize="sm" mb="-1">
          CpF
        </Text>
        <VStack align="stretch" width="85%">
          <Progress
            value={typeof CpF_Score === "number" ? Number(CpF_Score) : 0}
            max={100}
            width="98%"
          />
        </VStack>
      </Flex>
    </Box>
  );
}

const radarChartOptions: ChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    r: {
      min: 0,
      max: 5,
      ticks: {
        stepSize: 1,
        font: {
          // 目盛のフォント
          family: "Noto Sans JP, Oswald",
          size: 14,
          style: "normal",
        },
      },
      angleLines: {
        display: true,
      },
      pointLabels: {
        font: {
          // labelsのフォント
          size: 14,
          family: "Noto Sans JP, Oswald",
          style: "normal",
          weight: "bold",
        },
      },
    },
  },
};

const formatEmotion = (value: number, length: number, digit = 0): number => {
  return parseFloat((value / length).toFixed(digit));
};

const formatMi2 = (value: number, length: number, digit = 0): number => {
  return parseFloat(((value / length) * 100).toFixed(digit));
};

export function RenderResult({
  mi1Result,
  mi2Result,
}: Readonly<{
  mi1Result: Mi1Result;
  mi2Result: Mi2Result;
}>): ReactElement {
  const { t } = useTranslation();

  const convertMi2Result = ConvertMi2Result({ result: mi2Result });

  const tablePhrasesColumns: ResultDiagramIndex[] = [
    { width: "32px", title: "No" },
    { width: "100%", title: t("UixResult.voice_content"), align: "left" },
    { width: "30px", subWidth: "18px", title: t("UixResult.Mi1Mi2.joy") },
    { width: "30px", subWidth: "18px", title: t("UixResult.Mi1Mi2.ang") },
    { width: "30px", subWidth: "18px", title: t("UixResult.Mi1Mi2.srw") },
    { width: "30px", subWidth: "18px", title: t("UixResult.Mi1Mi2.clm") },
    { width: "30px", subWidth: "18px", title: t("UixResult.Mi1Mi2.exc") },
    { width: "40px", subWidth: "32px", title: "Z-H" },
    { width: "40px", subWidth: "32px", title: "CpF" },
    { width: "40px", subWidth: "32px", title: "HST" },
    { width: "40px", subWidth: "32px", title: "ZCR" },
    { width: "40px", subWidth: "32px", title: "sec" },
  ];

  const emotions = mi1Result ? mi1Result.mimosys_sub_parameters.emotions : [];
  const mi2_data_list = mi2Result ? mi2Result.mi2_data : [];
  const tablePhrasesData: ResultDiagramParam[][] = [];
  const uixPhrases = allPhrases["UIX"]["phrases"];

  let voiceDataList: VOICE_DATA[] = [];
  if (mi2_data_list.length > 0) {
    voiceDataList = mi2_data_list;
  } else if (emotions.length > 0) {
    voiceDataList = emotions;
  }

  // 同一ファイル名があるか確認
  const filenameAppearanceCounter: Record<number, number> = {};
  voiceDataList.forEach((voiceData) => {
    const wav_filename = voiceData.wav_filename;
    const phraseIndex = getPhraseNumberByFilename(wav_filename);

    if (!filenameAppearanceCounter[phraseIndex]) {
      filenameAppearanceCounter[phraseIndex] = 1;
    } else {
      filenameAppearanceCounter[phraseIndex]++;
    }
  });

  const filenameCounter: Record<number, number> = {};
  voiceDataList.forEach((voiceData) => {
    const wav_filename = voiceData.wav_filename;
    const phraseIndex = getPhraseNumberByFilename(wav_filename);
    if (!filenameCounter[phraseIndex]) {
      filenameCounter[phraseIndex] = 1;
    } else {
      filenameCounter[phraseIndex]++;
    }

    // 1つのファイルで複数発話ある場合はn-n、それ以外はn
    let No;
    if (filenameAppearanceCounter[phraseIndex] > 1) {
      No = `${phraseIndex + 1}-${filenameCounter[phraseIndex]}`;
    } else {
      No = `${phraseIndex + 1}`;
    }
    const time = ((voiceData.end_time - voiceData.start_time) / 1000).toFixed(
      1
    );
    const emotion = emotions.find(
      (emotion) =>
        emotion.wav_filename === wav_filename &&
        emotion.start_time === voiceData.start_time
    );
    const joy = emotion ? emotion.joy : "-";
    const ang = emotion ? emotion.ang : "-";
    const srw = emotion ? emotion.srw : "-";
    const clm = emotion ? emotion.clm : "-";
    const exc = emotion ? emotion.exc : "-";

    const mi2_data = mi2_data_list.find(
      (mi2) =>
        mi2.wav_filename === wav_filename &&
        mi2.start_time === voiceData.start_time
    );
    const zh_value = mi2_data ? Math.floor(mi2_data.zh_value * 100) : "-";
    const cpf_value = mi2_data ? Math.floor(mi2_data.cpf_value * 100) : "-";
    const hurst_exponent = mi2_data
      ? Math.floor(mi2_data.hurst_exponent * 100)
      : "-";
    const zero_cross_rate = mi2_data
      ? Math.floor(mi2_data.zero_cross_rate * 100)
      : "-";

    const phrase =
      uixPhrases[phraseIndex]["resultPhraseKey"] ??
      uixPhrases[phraseIndex]["phraseKey"];

    tablePhrasesData.push([
      { value: No },
      { value: t(phrase), align: "left" },
      { value: joy },
      { value: ang },
      { value: srw },
      { value: clm },
      { value: exc },
      { value: zh_value },
      { value: cpf_value },
      { value: hurst_exponent },
      { value: zero_cross_rate },
      { value: time },
    ]);
  });

  const totalEmotion = emotions.reduce(
    (total, emotion) => {
      total.joy += emotion.joy;
      total.ang += emotion.ang;
      total.srw += emotion.srw;
      total.clm += emotion.clm;
      total.exc += emotion.exc;
      return total;
    },
    { joy: 0, ang: 0, srw: 0, clm: 0, exc: 0 }
  );

  const totalMi2 = mi2_data_list.reduce(
    (total, mi2) => {
      total.zh_value += mi2.zh_value;
      total.cpf_value += mi2.cpf_value;
      total.hurst_exponent += mi2.hurst_exponent;
      total.zero_cross_rate += mi2.zero_cross_rate;
      return total;
    },
    { zh_value: 0, cpf_value: 0, hurst_exponent: 0, zero_cross_rate: 0 }
  );

  const totalTime = voiceDataList.reduce(
    (sum, voiceData) =>
      sum + (voiceData.end_time - voiceData.start_time) / 1000,
    0
  );

  const averageJoy = formatEmotion(totalEmotion.joy, emotions.length);
  const averageAng = formatEmotion(totalEmotion.ang, emotions.length);
  const averageSrw = formatEmotion(totalEmotion.srw, emotions.length);
  const averageClm = formatEmotion(totalEmotion.clm, emotions.length);
  const averageExc = formatEmotion(totalEmotion.exc, emotions.length);

  const averageZhValue = formatMi2(totalMi2.zh_value, mi2_data_list.length);
  const averageCpfValue = formatMi2(totalMi2.cpf_value, mi2_data_list.length);
  const averageHst = formatMi2(totalMi2.hurst_exponent, mi2_data_list.length);
  const averageZcr = formatMi2(totalMi2.zero_cross_rate, mi2_data_list.length);
  const averageTime = parseFloat((totalTime / voiceDataList.length).toFixed(1));

  if (1 < voiceDataList.length) {
    const formatTableColumn = (value: number): string => {
      return !isNaN(value) ? value.toFixed(0) : "-";
    };

    tablePhrasesData.push([
      { value: "" },
      { value: t("UixResult.Mi1Mi2.average"), align: "left" },
      { value: formatTableColumn(averageJoy) },
      { value: formatTableColumn(averageAng) },
      { value: formatTableColumn(averageSrw) },
      { value: formatTableColumn(averageClm) },
      { value: formatTableColumn(averageExc) },
      { value: formatTableColumn(averageZhValue) },
      { value: formatTableColumn(averageCpfValue) },
      { value: formatTableColumn(averageHst) },
      { value: formatTableColumn(averageZcr) },
      { value: formatTableColumn(averageTime) },
    ]);
  }

  const theme = useTheme();

  const radarChartData: ChartData = {
    labels: [
      t("UixResult.Mi1Mi2.joy"),
      t("UixResult.Mi1Mi2.ang"),
      t("UixResult.Mi1Mi2.srw"),
      t("UixResult.Mi1Mi2.clm"),
      t("UixResult.Mi1Mi2.exc"),
    ],
    datasets: [
      {
        label: "",
        data: [averageJoy, averageAng, averageSrw, averageClm, averageExc],
        backgroundColor: hexToRgba(theme.colors.primary["theme_lv1"], 0.2),
        borderColor: theme.colors.primary["theme_lv1"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Stack spacing={2}>
      <RenderMainResult
        imgSrc={mi2mi2Image}
        content={createElement(RenderMainContent, { result: convertMi2Result })}
      />
      <Box bg="white" position="relative">
        <Box position="absolute" left="10px" top="10px" zIndex="1">
          <Text>{t("UixResult.Mi1Mi2.voice_emotional_balance")}</Text>
        </Box>
        <Box width="300px" margin="auto" mt={2}>
          <RenderRadarChart
            radarChartData={radarChartData}
            radarChartOptions={radarChartOptions}
          />
        </Box>
      </Box>
      <RenderUixTable
        diagramIndex={tablePhrasesColumns}
        diagramParams={tablePhrasesData}
      />
    </Stack>
  );
}
