import {
  BaseAnalysisEngine,
  BaseAnalysisResult,
  isOfType,
} from "./baseAnalysisEngine";
import { ReactElement } from "react";
import { TFunction } from "react-i18next";
import { ResultDashboard } from "../components/molecules/ResultDashboard";
import {
  ApiEngineType,
  ResultDashboardData,
  ResultMainParam,
  ResultSubParam,
} from "../types";

export type AnalysisResult = BaseAnalysisResult & {
  np1_result: string;
  np1_score: number;
  np1_missing_info: Record<string, number>;
  np1_sub_parameters: Record<string, object>;
};

export type ConvertResultType = {
  np1_result: string;
  np1_score: string | number;
  ParamA_LPV_H: string | number;
  ParamA_LPV_CPPS: string | number;
  ParamB_LPV_H: string | number;
  ParamB_LPV_CPPS: string | number;
  ParamC_LPV_H: string | number;
  ParamD_LPV_S: string | number;
  ParamD_LPV_H: string | number;
  ParamD_LPV_D: string | number;
  ParamD_LPV_DR: string | number;
  DSTP_PSD: string | number;
  DSTP_PSA: string | number;
  DSTP_PSS: string | number;
  DSTP_DR: string | number;
};

const SUB_PARAMS_TITLE_LIST = ["Result.Dementia_score"];

const DEFAULT_DASHBOARD_MAIN_PARAM: ResultMainParam = {
  title: "Result.Dementia_rank",
  // border: "solid 3px #EFB6CB",
  value: undefined,
};

const ERROR_DASHBOARD_DATA: ResultDashboardData = {
  mainParam: DEFAULT_DASHBOARD_MAIN_PARAM,
  subParams: [
    {
      title: SUB_PARAMS_TITLE_LIST[0],
      value: undefined,
    },
  ],
  supplementaryInfo: [],
  diagramIndex: [],
  diagramParams: [],
};

export function ConvertResult({
  result,
}: {
  result: AnalysisResult;
}): ConvertResultType {
  if (!result) {
    return {
      np1_result: "-",
      np1_score: "-",
      ParamA_LPV_H: "-",
      ParamA_LPV_CPPS: "-",
      ParamB_LPV_H: "-",
      ParamB_LPV_CPPS: "-",
      ParamC_LPV_H: "-",
      ParamD_LPV_S: "-",
      ParamD_LPV_H: "-",
      ParamD_LPV_D: "-",
      ParamD_LPV_DR: "-",
      DSTP_PSD: "-",
      DSTP_PSA: "-",
      DSTP_PSS: "-",
      DSTP_DR: "-",
    };
  }
  const np1_score = result.np1_score;
  type SubItem01 = {
    ParamA?: {
      LVP_H?: number;
      LVP_CPPS?: number;
    };
    ParamB?: {
      LVP_H?: number;
      LVP_CPPS?: number;
    };
    ParamC?: {
      LVP_H?: number;
    };
    ParamD: {
      LVP_S?: number;
      LVP_H?: number;
      LVP_D?: number;
      LVP_DR: number;
    };
  };

  type SubItem02 = {
    ParamD: {
      DSTP_PSD?: number;
      DSTP_PSA?: number;
      DSTP_PSS?: number;
      DSTP_DR: number;
    };
  };

  const sampleSubItem01: SubItem01 = { ParamD: { LVP_DR: 0 } };
  const sampleSubItem02: SubItem02 = { ParamD: { DSTP_DR: 0 } };

  const isSubItem01 = (item: unknown): item is SubItem01 =>
    isOfType<SubItem01>(item, sampleSubItem01);
  const isSubItem02 = (item: unknown): item is SubItem02 =>
    isOfType<SubItem02>(item, sampleSubItem02);

  const DEFAULT_VALUE = 0.0;
  // 「長母音」
  const subItem01 = Object.values(result.np1_sub_parameters).find(
    (subParameters) => {
      return isSubItem01(subParameters);
    }
  ) as SubItem01 | undefined;

  const ParamA_LPV_H = subItem01?.ParamA?.LVP_H ?? DEFAULT_VALUE; // 前半HNR
  const ParamA_LPV_CPPS = subItem01?.ParamA?.LVP_CPPS ?? DEFAULT_VALUE; // 前半CPPS
  const ParamB_LPV_H = subItem01?.ParamB?.LVP_H ?? DEFAULT_VALUE; // 前半HNR
  const ParamB_LPV_CPPS = subItem01?.ParamB?.LVP_CPPS ?? DEFAULT_VALUE; // 中盤CPPS
  const ParamC_LPV_H = subItem01?.ParamC?.LVP_H ?? DEFAULT_VALUE; // 中盤
  const ParamD_LPV_S = subItem01?.ParamD?.LVP_S ?? DEFAULT_VALUE; // 中盤Shimmer
  const ParamD_LPV_H = subItem01?.ParamD?.LVP_H ?? DEFAULT_VALUE; // 後半HNR
  const ParamD_LPV_D = subItem01?.ParamD?.LVP_D ?? DEFAULT_VALUE; // 後半DTW
  const ParamD_LPV_DR = subItem01?.ParamD?.LVP_DR ?? DEFAULT_VALUE; // 後半Duration

  // 「パタカ」
  const subItem02 = Object.values(result.np1_sub_parameters).find(
    (subParameters) => {
      return isSubItem02(subParameters);
    }
  ) as SubItem02 | undefined;

  const DSTP_PSD = subItem02?.ParamD?.DSTP_PSD ?? DEFAULT_VALUE; // Peak SD
  const DSTP_PSA = subItem02?.ParamD?.DSTP_PSA ?? DEFAULT_VALUE; // Peak Span Ave(発声の速さ)
  const DSTP_PSS = subItem02?.ParamD?.DSTP_PSS ?? DEFAULT_VALUE; // Peak Span SD(発声リズムの安定性)
  const DSTP_DR = subItem02?.ParamD?.DSTP_DR ?? DEFAULT_VALUE; // Duration「パタカ」の長さ

  const formatParameter = (param: number, fractionDigits: number): string => {
    return isNaN(param) ? "-" : param.toFixed(fractionDigits);
  };

  return {
    np1_result: result.np1_result,
    np1_score: np1_score,
    ParamA_LPV_H: formatParameter(ParamA_LPV_H, 2),
    ParamA_LPV_CPPS: formatParameter(ParamA_LPV_CPPS, 2),
    ParamB_LPV_H: formatParameter(ParamB_LPV_H, 2),
    ParamB_LPV_CPPS: formatParameter(ParamB_LPV_CPPS, 2),
    ParamC_LPV_H: formatParameter(ParamC_LPV_H, 2),
    ParamD_LPV_S: formatParameter(ParamD_LPV_S, 4),
    ParamD_LPV_H: formatParameter(ParamD_LPV_H, 2),
    ParamD_LPV_D: formatParameter(ParamD_LPV_D, 2),
    ParamD_LPV_DR: formatParameter(ParamD_LPV_DR, 1),
    DSTP_PSD: formatParameter(DSTP_PSD, 2),
    DSTP_PSA: formatParameter(DSTP_PSA, 2),
    DSTP_PSS: formatParameter(DSTP_PSS, 2),
    DSTP_DR: formatParameter(DSTP_DR, 1),
  };
}

export class Np1Engine extends BaseAnalysisEngine<AnalysisResult> {
  _resultCaches: Record<string, AnalysisResult> = {};
  _engineType: ApiEngineType = "np1";
  _apiPath = "/analysis/np1";

  private static convertToDashboardData(
    result: AnalysisResult
  ): ResultDashboardData {
    const mainParam: ResultMainParam = {
      ...DEFAULT_DASHBOARD_MAIN_PARAM,
      value: result.np1_result,
    };
    const subParams: ResultSubParam[] = [
      {
        title: SUB_PARAMS_TITLE_LIST[0],
        value: result.np1_score,
      },
    ];

    return {
      mainParam,
      subParams,
      supplementaryInfo: [],
      diagramIndex: [],
      diagramParams: [],
    };
  }

  async renderResult(
    key: number,
    fileId: string,
    t: TFunction
  ): Promise<ReactElement> {
    const result = await this.getResult(fileId);
    const dashboardData = result
      ? Np1Engine.convertToDashboardData(result)
      : ERROR_DASHBOARD_DATA;
    return (
      <ResultDashboard
        key={key}
        title={t("Result.Dementia_analysis_result") + "(NP-1)"}
        result={dashboardData}
      />
    );
  }
}
