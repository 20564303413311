import { ReactElement } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ResultDashboardData } from "../../types";
import { DashboardBox } from "../atoms/DashboardBox";
import { ResultDiagram } from "../atoms/ResultDiagram";
import { AnalysisResultCardLayout } from "../atoms/AnalysisResultCardLayout";

export function ResultDashboard(props: Readonly<{
  title: string;
  result: ResultDashboardData;
}>): ReactElement {
  const { t } = useTranslation();
  return (
    <AnalysisResultCardLayout title={props.title}>
      <Flex gap={2}>
        {props.result.supplementaryInfo.map((info, infoIndex) => {
          return (
            <Box key={infoIndex} fontSize="12px">
              {t(info.title) +
                ":" +
                (info.value !== undefined ? info.value : "Error")}
              {infoIndex !== props.result.supplementaryInfo.length - 1 && ", "}
            </Box>
          );
        })}
      </Flex>
      <DashboardBox result={props.result} />
      {props.result.diagramParams.length !== 0 && (
        <ResultDiagram
          diagramParams={props.result.diagramParams}
          diagramIndex={props.result.diagramIndex}
        />
      )}
    </AnalysisResultCardLayout>
  );
}
