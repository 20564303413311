import { forwardRef } from "react";
import { PhraseText, SoundVisualizerRef } from "../../types";
import { Box, Center, Text, useTheme } from "@chakra-ui/react";
import { BarSoundVisualizerV2 } from "../atoms/BarSoundVisualizerV2";
import { APP_TYPE } from "../../environments";
import { CALIBRATION_MIN_LOG_POWER } from "../../constants";
import { decibelToVolume } from "../../utils/calcVolume";
import { useTranslation } from "react-i18next";

type PhraseVisualizerProps = {
  currentPhrase: PhraseText;
};
export const PhraseBarSoundVisualizer = forwardRef<
  SoundVisualizerRef,
  PhraseVisualizerProps
>((props, ref) => {
  const theme = useTheme();
  const underThreshold = decibelToVolume(CALIBRATION_MIN_LOG_POWER);
  const { t } = useTranslation();
  const isSompoComplete =
    APP_TYPE === "sompo" &&
    props.currentPhrase.phrase ===
      t("PJ.OralFunctionAnalysis.Completed.Phrase");
  const defaultFontSize = APP_TYPE === "sompo" ? "4xl" : "2xl";
  const defaultSmFontSize = APP_TYPE === "sompo" ? "6xl" : "4xl";

  return (
    <Box
      position="relative"
      minHeight={{
        base: "320px",
        sm: "440px",
      }}
      width="100%"
    >
      <BarSoundVisualizerV2
        ref={ref}
        upperThreshold={0.81}
        underThreshold={underThreshold}
        maxValue={1}
        height="40px"
        width="100%"
      />
      <Center
        position="absolute"
        w="full"
        h="370px"
        flexDirection="column"
        mt="20px"
        justifyContent="flex-start"
      >
        <Text
          maxWidth="100%"
          fontSize={{
            base: isSompoComplete ? "3x1" : defaultFontSize,
            sm: isSompoComplete ? "4xl" : defaultSmFontSize,
          }}
          textAlign="center"
          whiteSpace="pre-wrap"
          color="primary.theme_lv1"
          fontWeight={900}
          lineHeight={1.2}
          width="100%"
          height={{
            base: "200px",
            sm: "250px",
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderWidth="2px"
          borderStyle="solid"
          borderColor={theme.colors.primary["theme_lv1"]}
          bg={theme.colors.common["base"]}
        >
          {props.currentPhrase.phrase}
        </Text>
        <Text
          width="100%"
          textAlign="center"
          whiteSpace="pre-wrap"
          fontSize={{
            base: "xl",
            sm: "2xl",
          }}
          mt="10px"
        >
          {props.currentPhrase.helperText || ""}
        </Text>
      </Center>
    </Box>
  );
});
PhraseBarSoundVisualizer.displayName = "PhraseVisualizer";
