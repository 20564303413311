import React, { ReactElement } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  BoxProps,
  HTMLChakraProps,
  Stack,
  Center,
  Box,
  useTheme,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  AnalysisResult as CogResult,
  ConvertResult as ConvertCogResult,
  ConvertResultType as ConvertCogResultType,
} from "../../engines/cogEngine";
import {
  AnalysisResult as Co1Result,
  ConvertResult as ConvertCo1Result,
  ConvertResultType as ConvertCo1ResultType,
} from "../../engines/co1Engine";
import {
  AnalysisResult as Np1Result,
  ConvertResult as ConvertNp1Result,
  ConvertResultType as ConvertNp1ResultType,
} from "../../engines/np1Engine";

export type CognitiveResultType = CogResult | Co1Result | Np1Result;
type ThProps = HTMLChakraProps<"th"> & BoxProps;
type TdProps = HTMLChakraProps<"td"> & BoxProps;

function SubTitle({ subTitle }: Readonly<{ subTitle: string }>): ReactElement {
  return (
    <Center
      h="40px"
      bg="primary.theme_lv2"
      color="text.main_text_lv1"
      borderRadius="30px"
      fontWeight="bold"
      fontSize="16px"
      mb="10px"
    >
      {subTitle}
    </Center>
  );
}

const CustomTh: React.FC<ThProps> = ({ children, textAlign, ...props }) => {
  const theme = useTheme();
  const borderColor = theme.colors.primary["theme_lv1"];
  const effectiveAlign = textAlign || "center";
  const effectivePaddingLeft = useBreakpointValue({ base: "1", md: "4" });
  const paddingLeft = effectiveAlign === "center" ? "0" : effectivePaddingLeft;
  return (
    <Th
      border={`1px solid ${borderColor}`}
      textAlign={effectiveAlign}
      textTransform="none"
      lineHeight="1px"
      fontSize="md"
      height="30px"
      pl={paddingLeft}
      pr={0}
      {...props}
    >
      {children}
    </Th>
  );
};

const CustomTd: React.FC<TdProps> = ({ children, textAlign, ...props }) => {
  const theme = useTheme();
  const borderColor = theme.colors.primary["theme_lv1"];
  const effectiveAlign = textAlign || "center";
  const effectivePaddingLeft = useBreakpointValue({ base: "1", md: "4" });
  const paddingLeft = effectiveAlign === "center" ? "0" : effectivePaddingLeft;
  return (
    <Td
      border={`1px solid ${borderColor}`}
      textAlign={effectiveAlign}
      lineHeight="1px"
      height="30px"
      bg="white"
      pl={paddingLeft}
      pr={0}
      {...props}
    >
      {children}
    </Td>
  );
};

function RenderMainTable({
  cogResult,
  co1Result,
  np1Result,
}: Readonly<{
  cogResult: ConvertCogResultType;
  co1Result: ConvertCo1ResultType;
  np1Result: ConvertNp1ResultType;
}>): ReactElement {
  const width1 = useBreakpointValue({ base: "135px", md: "210px" });
  const width2 = useBreakpointValue({ base: "80px", md: "110px" });
  return (
    <Box overflowX="auto">
      <Table style={{ tableLayout: "fixed" }}>
        <Thead bg="primary.theme_lv2">
          <Tr>
            <CustomTh width={width1}>{}</CustomTh>
            <CustomTh width={width2}>Cog</CustomTh>
            <CustomTh width={width2}>Co-1</CustomTh>
            <CustomTh width={width2}>NP-1</CustomTh>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <CustomTd textAlign="left">認知機能評価</CustomTd>
            <CustomTd>{cogResult.cognitive_assessment}</CustomTd>
            <CustomTd>{co1Result.Co1_result}</CustomTd>
            <CustomTd>{np1Result.np1_result}</CustomTd>
          </Tr>
          <Tr>
            <CustomTd textAlign="left">認知機能スコア</CustomTd>
            <CustomTd>{cogResult.cognitive_score}</CustomTd>
            <CustomTd>{co1Result.Co1_score}</CustomTd>
            <CustomTd>{np1Result.np1_score}</CustomTd>
          </Tr>
          <Tr>
            <CustomTd textAlign="left">正常確度</CustomTd>
            <CustomTd>{cogResult.no}</CustomTd>
            <CustomTd bg="primary.theme_lv1">{}</CustomTd>
            <CustomTd bg="primary.theme_lv1">{}</CustomTd>
          </Tr>
          <Tr>
            <CustomTd textAlign="left">軽度確度</CustomTd>
            <CustomTd>{cogResult.mci}</CustomTd>
            <CustomTd bg="primary.theme_lv1">{}</CustomTd>
            <CustomTd bg="primary.theme_lv1">{}</CustomTd>
          </Tr>
          <Tr>
            <CustomTd textAlign="left">中等度確度</CustomTd>
            <CustomTd>{cogResult.dmz}</CustomTd>
            <CustomTd bg="primary.theme_lv1">{}</CustomTd>
            <CustomTd bg="primary.theme_lv1">{}</CustomTd>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
}

function RenderLongvowelTable({
  cogResult,
  co1Result,
  np1Result,
}: Readonly<{
  cogResult: ConvertCogResultType;
  co1Result: ConvertCo1ResultType;
  np1Result: ConvertNp1ResultType;
}>): ReactElement {
  const width1 = useBreakpointValue({ base: "50px", md: "90px" });
  const width2 = useBreakpointValue({ base: "85px", md: "120px" });
  const width3 = useBreakpointValue({ base: "80px", md: "110px" });
  return (
    <>
      <SubTitle subTitle="長母音" />
      <Box overflowX="auto">
        <Table style={{ tableLayout: "fixed" }}>
          <colgroup>
            <col style={{ width: width1 }} />
            <col style={{ width: width2 }} />
            <col style={{ width: width3 }} />
            <col style={{ width: width3 }} />
            <col style={{ width: width3 }} />
          </colgroup>
          <Thead bg="primary.theme_lv2">
            <Tr>
              <CustomTh colSpan={2}>{}</CustomTh>
              <CustomTh>Cog</CustomTh>
              <CustomTh>Co-1</CustomTh>
              <CustomTh>NP-1</CustomTh>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <CustomTd textAlign="left" colSpan={2}>
                Duration
              </CustomTd>
              <CustomTd bg="primary.theme_lv1">{}</CustomTd>
              <CustomTd>{co1Result.ParamD_LVP_DR}</CustomTd>
              <CustomTd>{np1Result.ParamD_LPV_DR}</CustomTd>
            </Tr>
            <Tr>
              <CustomTd rowSpan={4}>前半</CustomTd>
              <CustomTd textAlign="left">Shimmer</CustomTd>
              <CustomTd>{cogResult.ParamA_LVP_S}</CustomTd>
              <CustomTd>{co1Result.ParamA_LVP_S}</CustomTd>
              <CustomTd bg="primary.theme_lv1">{}</CustomTd>
            </Tr>
            <Tr>
              <CustomTd textAlign="left">Jitter</CustomTd>
              <CustomTd>{cogResult.ParamA_LVP_J}</CustomTd>
              <CustomTd>{co1Result.ParamA_LVP_J}</CustomTd>
              <CustomTd bg="primary.theme_lv1">{}</CustomTd>
            </Tr>
            <Tr>
              <CustomTd textAlign="left">HNR</CustomTd>
              <CustomTd>{cogResult.ParamA_LVP_H}</CustomTd>
              <CustomTd>{co1Result.ParamA_LVP_H}</CustomTd>
              <CustomTd>{np1Result.ParamA_LPV_H}</CustomTd>
            </Tr>
            <Tr>
              <CustomTd textAlign="left">CPPS</CustomTd>
              <CustomTd bg="primary.theme_lv1">{}</CustomTd>
              <CustomTd bg="primary.theme_lv1">{}</CustomTd>
              <CustomTd>{np1Result.ParamA_LPV_CPPS}</CustomTd>
            </Tr>
            <Tr>
              <CustomTd rowSpan={4}>中盤</CustomTd>
              <CustomTd textAlign="left">Shimmer</CustomTd>
              <CustomTd>{co1Result.ParamB_LVP_S}</CustomTd>
              <CustomTd>{co1Result.ParamB_LVP_S}</CustomTd>
              <CustomTd bg="primary.theme_lv1">{}</CustomTd>
            </Tr>
            <Tr>
              <CustomTd textAlign="left">Jitter</CustomTd>
              <CustomTd>{cogResult.ParamB_LVP_J}</CustomTd>
              <CustomTd>{co1Result.ParamB_LVP_J}</CustomTd>
              <CustomTd bg="primary.theme_lv1">{}</CustomTd>
            </Tr>
            <Tr>
              <CustomTd textAlign="left">HNR</CustomTd>
              <CustomTd>{cogResult.ParamB_LVP_H}</CustomTd>
              <CustomTd>{co1Result.ParamB_LVP_H}</CustomTd>
              <CustomTd>{np1Result.ParamB_LPV_H}</CustomTd>
            </Tr>
            <Tr>
              <CustomTd textAlign="left">CPPS</CustomTd>
              <CustomTd bg="primary.theme_lv1">{}</CustomTd>
              <CustomTd bg="primary.theme_lv1">{}</CustomTd>
              <CustomTd>{np1Result.ParamB_LPV_CPPS}</CustomTd>
            </Tr>
            <Tr>
              <CustomTd rowSpan={3}>後半</CustomTd>
              <CustomTd textAlign="left">Shimmer</CustomTd>
              <CustomTd>{cogResult.ParamC_LVP_S}</CustomTd>
              <CustomTd>{co1Result.ParamC_LVP_S}</CustomTd>
              <CustomTd bg="primary.theme_lv1">{}</CustomTd>
            </Tr>
            <Tr>
              <CustomTd textAlign="left">Jitter</CustomTd>
              <CustomTd>{cogResult.ParamC_LVP_J}</CustomTd>
              <CustomTd>{co1Result.ParamC_LVP_J}</CustomTd>
              <CustomTd bg="primary.theme_lv1">{}</CustomTd>
            </Tr>
            <Tr>
              <CustomTd textAlign="left">HNR</CustomTd>
              <CustomTd>{cogResult.ParamC_LVP_H}</CustomTd>
              <CustomTd>{co1Result.ParamC_LVP_H}</CustomTd>
              <CustomTd>{np1Result.ParamC_LPV_H}</CustomTd>
            </Tr>
            <Tr>
              <CustomTd rowSpan={4}>全体</CustomTd>
              <CustomTd textAlign="left">Shimmer</CustomTd>
              <CustomTd>{cogResult.ParamD_LVP_S}</CustomTd>
              <CustomTd>{co1Result.ParamD_LVP_S}</CustomTd>
              <CustomTd>{np1Result.ParamD_LPV_S}</CustomTd>
            </Tr>
            <Tr>
              <CustomTd textAlign="left">Jitter</CustomTd>
              <CustomTd>{cogResult.ParamD_LVP_J}</CustomTd>
              <CustomTd>{co1Result.ParamD_LVP_J}</CustomTd>
              <CustomTd bg="primary.theme_lv1">{}</CustomTd>
            </Tr>
            <Tr>
              <CustomTd textAlign="left">HNR</CustomTd>
              <CustomTd>{cogResult.ParamD_LVP_H}</CustomTd>
              <CustomTd>{co1Result.ParamD_LVP_H}</CustomTd>
              <CustomTd>{np1Result.ParamD_LPV_H}</CustomTd>
            </Tr>
            <Tr>
              <CustomTd textAlign="left">DTW</CustomTd>
              <CustomTd>{cogResult.ParamD_LVP_D}</CustomTd>
              <CustomTd>{co1Result.ParamD_LVP_D}</CustomTd>
              <CustomTd>{np1Result.ParamD_LPV_D}</CustomTd>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </>
  );
}

function RenderPatakaTable({
  cogResult,
  co1Result,
  np1Result,
}: Readonly<{
  cogResult: ConvertCogResultType;
  co1Result: ConvertCo1ResultType;
  np1Result: ConvertNp1ResultType;
}>): ReactElement {
  const width1 = useBreakpointValue({ base: "135px", md: "210px" });
  const width2 = useBreakpointValue({ base: "80px", md: "110px" });
  return (
    <>
      <SubTitle subTitle="パタカ" />
      <Box overflowX="auto">
        <Table style={{ tableLayout: "fixed" }}>
          <Thead bg="primary.theme_lv2">
            <Tr>
              <CustomTh width={width1}>{}</CustomTh>
              <CustomTh width={width2}>Cog</CustomTh>
              <CustomTh width={width2}>Co-1</CustomTh>
              <CustomTh width={width2}>NP-1</CustomTh>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <CustomTd textAlign="left">Duration</CustomTd>
              <CustomTd bg="primary.theme_lv1">{}</CustomTd>
              <CustomTd>{co1Result.DSTP_DR}</CustomTd>
              <CustomTd>{np1Result.DSTP_DR}</CustomTd>
            </Tr>
            <Tr>
              <CustomTd textAlign="left">All Slope</CustomTd>
              <CustomTd>{cogResult.DSTP_AS}</CustomTd>
              <CustomTd>{co1Result.DSTP_AS}</CustomTd>
              <CustomTd bg="primary.theme_lv1">{}</CustomTd>
            </Tr>
            <Tr>
              <CustomTd textAlign="left">Peak SD</CustomTd>
              <CustomTd>{cogResult.DSTP_PS}</CustomTd>
              <CustomTd>{co1Result.DSTP_PS}</CustomTd>
              <CustomTd>{np1Result.DSTP_PSD}</CustomTd>
            </Tr>
            <Tr>
              <CustomTd textAlign="left">Peak Span Ave.</CustomTd>
              <CustomTd>{cogResult.DSTP_PSA}</CustomTd>
              <CustomTd>{co1Result.DSTP_PSA}</CustomTd>
              <CustomTd>{np1Result.DSTP_PSA}</CustomTd>
            </Tr>
            <Tr>
              <CustomTd textAlign="left">Peak Span SD</CustomTd>
              <CustomTd>{cogResult.DSTP_PSS}</CustomTd>
              <CustomTd>{co1Result.DSTP_PSS}</CustomTd>
              <CustomTd>{np1Result.DSTP_PSS}</CustomTd>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </>
  );
}

export function ResultCognitive({
  resultsMap,
}: Readonly<{
  resultsMap: Map<string, CognitiveResultType>;
}>): ReactElement {
  const cogResult = resultsMap.get("cog") as CogResult;
  const co1Result = resultsMap.get("co1") as Co1Result;
  const np1Result = resultsMap.get("np1") as Np1Result;
  const converCogResult = ConvertCogResult({ result: cogResult });
  const converCo1Result = ConvertCo1Result({ result: co1Result });
  const converNp1Result = ConvertNp1Result({ result: np1Result });

  return (
    <Stack spacing={4} mb={4}>
      <RenderMainTable
        cogResult={converCogResult}
        co1Result={converCo1Result}
        np1Result={converNp1Result}
      />
      <RenderLongvowelTable
        cogResult={converCogResult}
        co1Result={converCo1Result}
        np1Result={converNp1Result}
      />
      <RenderPatakaTable
        cogResult={converCogResult}
        co1Result={converCo1Result}
        np1Result={converNp1Result}
      />
    </Stack>
  );
}
