import { ReactElement } from "react";
import { Table, Tbody, Td, Thead, Th, Tr } from "@chakra-ui/react";
import {
  ResultDiagramParam,
  ResultDiagramIndex,
  TableAlign,
} from "../../types";

function CustomTh({
  width,
  subWidth,
  title,
  textAlign,
}: Readonly<{
  key: number;
  width: string;
  subWidth?: string;
  title: string;
  textAlign?: TableAlign;
}>): ReactElement {
  const effectiveAlign = textAlign || "center";
  return (
    <Th
      width={{ base: subWidth ?? width, sm: width }}
      fontSize="sm"
      borderWidth="1px"
      borderColor="black"
      paddingLeft={effectiveAlign === "left" ? 2 : 0}
      paddingY={1}
      textAlign={effectiveAlign}
      style={
        effectiveAlign === "center"
          ? { paddingInlineStart: 0, paddingInlineEnd: 0 }
          : {}
      }
    >
      {title}
    </Th>
  );
}

function CustomTd({
  value,
  textAlign,
}: Readonly<{
  value: number | string;
  textAlign?: TableAlign;
}>): ReactElement {
  const effectiveAlign = textAlign || "center";
  return (
    <Td
      fontSize="sm"
      borderWidth="1px"
      borderColor="black"
      paddingY={1}
      textAlign={effectiveAlign}
      paddingX={0}
      paddingInlineStart={{
        base: effectiveAlign === "left" ? 2 : 0,
        sm: effectiveAlign === "left" ? 15 : 0,
      }}
    >
      {value}
    </Td>
  );
}

export function ResultDiagram(props: Readonly<{
  diagramParams: ResultDiagramParam[][];
  diagramIndex: ResultDiagramIndex[];
}>): ReactElement {
  return (
    <Table style={{ tableLayout: "fixed" }} bg="common.base">
      <Thead bg="#EEEEEE">
        <Tr>
          {props.diagramIndex &&
            props.diagramIndex.map((value, index) => (
              <CustomTh
                key={index}
                width={value.width}
                title={value.title}
                textAlign={value.align}
              />
            ))}
        </Tr>
      </Thead>
      <Tbody>
        {props.diagramParams &&
          props.diagramParams.map((value, index) => (
            <Tr key={index}>
              {value &&
                value.map((value2, index2) => (
                  <CustomTd
                    key={index2}
                    value={value2.value}
                    textAlign={value2.align}
                  />
                ))}
            </Tr>
          ))}
      </Tbody>
    </Table>
  );
}
