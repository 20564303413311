import { ReactElement, useEffect } from "react";
import { Button, VStack, useTheme } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Layout } from "../../components/atoms/Layout";
import { ENABLED_ENGINES } from "../../environments";
import { useAtom } from "jotai";
import {
  accessTokenInfoAtom,
  allEngineEnabledAtom,
  engineTypesAtom,
} from "../../store";
import { AnalysisEngineType } from "../../types";

export function getSelectableEngineList(
  engineTypes: object,
  allEngineEnabled: boolean
): AnalysisEngineType[] {
  const Cognitive_enable =
    ENABLED_ENGINES.includes("Cognitive") && allEngineEnabled;
  const cog_enable =
    ENABLED_ENGINES.includes("cog") &&
    (allEngineEnabled || "cog" in engineTypes || "co1" in engineTypes);
  const co1_enable =
    ENABLED_ENGINES.includes("co1") &&
    (allEngineEnabled || "co1" in engineTypes);
  const np1_enable =
    ENABLED_ENGINES.includes("np1") &&
    (allEngineEnabled || "np1" in engineTypes);
  const dysphagia_enable =
    ENABLED_ENGINES.includes("dysphagia") &&
    (allEngineEnabled || "dysphagia" in engineTypes);
  const mi2_enable =
    ENABLED_ENGINES.includes("Mi1_Mi2") &&
    (allEngineEnabled || "mi1" in engineTypes || "mi2" in engineTypes);
  const br1_enable =
    ENABLED_ENGINES.includes("br1") &&
    (allEngineEnabled || "br1" in engineTypes);
  const UIX_enable = ENABLED_ENGINES.includes("UIX") && allEngineEnabled;
  const Co1Demo_enable =
    ENABLED_ENGINES.includes("Co1Demo") &&
    (allEngineEnabled || "co1" in engineTypes);
  const NTT_enable =
    ENABLED_ENGINES.includes("NTT") &&
    (allEngineEnabled || "mi1" in engineTypes);
  const sl1_enable =
    ENABLED_ENGINES.includes("sl1") &&
    (allEngineEnabled || "sl1" in engineTypes);
  const Mi2Demo_enable =
    ENABLED_ENGINES.includes("Mi2Demo") &&
    (allEngineEnabled || "mi2" in engineTypes);
  const Co2Demo_enable =
    ENABLED_ENGINES.includes("Co2Demo") &&
    (allEngineEnabled || "co2" in engineTypes);
  const TFH_enable =
    ENABLED_ENGINES.includes("TFH") &&
    (allEngineEnabled || ("mi2" in engineTypes && "co2" in engineTypes));
  const TokioDRSl1_enable = ENABLED_ENGINES.includes("TokioDRSl1");
  const TokioDRCognitive_enable = ENABLED_ENGINES.includes("TokioDRCognitive");

  const options: { enabled: boolean; engine: AnalysisEngineType }[] = [
    { enabled: Cognitive_enable, engine: "Cognitive" },
    { enabled: cog_enable, engine: "cog" },
    { enabled: co1_enable, engine: "co1" },
    { enabled: np1_enable, engine: "np1" },
    { enabled: dysphagia_enable, engine: "dysphagia" },
    { enabled: mi2_enable, engine: "Mi1_Mi2" },
    { enabled: br1_enable, engine: "br1" },
    { enabled: UIX_enable, engine: "UIX" },
    { enabled: Co1Demo_enable, engine: "Co1Demo" },
    { enabled: NTT_enable, engine: "NTT" },
    { enabled: sl1_enable, engine: "sl1" },
    { enabled: Mi2Demo_enable, engine: "Mi2Demo" },
    { enabled: Co2Demo_enable, engine: "Co2Demo" },
    { enabled: TFH_enable, engine: "TFH" },
    { enabled: TokioDRSl1_enable, engine: "TokioDRSl1" },
    { enabled: TokioDRCognitive_enable, engine: "TokioDRCognitive" },
  ];

  return options
    .filter((engine) => {
      return engine.enabled;
    })
    .map((option) => {
      return option.engine;
    });
}

function EngineSelectionButton({
  engineKey,
}: Readonly<{
  engineKey: string;
}>): ReactElement {
  const { t } = useTranslation();
  return (
    <Button
      as={Link}
      to={`../${engineKey}/questionnaires`}
      width="full"
      variant="btn_primary"
      size="lg"
    >
      {t(`EngineSelection.${engineKey}`)}
    </Button>
  );
}

export function Engines(): ReactElement {
  const [tokenInfo] = useAtom(accessTokenInfoAtom);
  if (tokenInfo === null) throw new Error("no token");

  const [engineTypes] = useAtom(engineTypesAtom);
  const [allEngineEnabled] = useAtom(allEngineEnabledAtom);
  const url_list = getSelectableEngineList(engineTypes, allEngineEnabled);

  const theme = useTheme();
  const backgroundColor = theme.colors.primary["bg_lv1"];

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [backgroundColor]);

  return (
    <Layout h="full">
      <VStack justify="center" minH="full" gap={12}>
        {url_list.includes("Mi1_Mi2") && (
          <EngineSelectionButton engineKey="Mi1_Mi2" />
        )}
        {url_list.includes("Mi2Demo") && (
          <EngineSelectionButton engineKey="Mi2Demo" />
        )}
        {url_list.includes("mi2") && <EngineSelectionButton engineKey="mi2" />}
        {url_list.includes("Cognitive") && (
          <EngineSelectionButton engineKey="Cognitive" />
        )}
        {url_list.includes("Co1Demo") && (
          <EngineSelectionButton engineKey="Co1Demo" />
        )}
        {url_list.includes("Co2Demo") && (
          <EngineSelectionButton engineKey="Co2Demo" />
        )}
        {url_list.includes("cog") && <EngineSelectionButton engineKey="cog" />}
        {url_list.includes("co1") && <EngineSelectionButton engineKey="co1" />}
        {url_list.includes("sl1") && <EngineSelectionButton engineKey="sl1" />}

        {url_list.includes("np1") && <EngineSelectionButton engineKey="np1" />}
        {url_list.includes("dysphagia") && (
          <EngineSelectionButton engineKey="dysphagia" />
        )}
        {url_list.includes("br1") && <EngineSelectionButton engineKey="br1" />}
        {url_list.includes("UIX") && <EngineSelectionButton engineKey="UIX" />}
        {url_list.includes("NTT") && <EngineSelectionButton engineKey="NTT" />}
        {url_list.includes("TFH") && <EngineSelectionButton engineKey="TFH" />}
        {url_list.includes("TokioDRCognitive") && (
          <EngineSelectionButton engineKey="TokioDRCognitive" />
        )}
        {url_list.includes("TokioDRSl1") && (
          <EngineSelectionButton engineKey="TokioDRSl1" />
        )}
      </VStack>
    </Layout>
  );
}
