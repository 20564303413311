import { ReactElement, createElement } from "react";
import { Box, Flex, Stack, Text, Progress, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import co1Image from "../../assets/images/uix/co1.png";
import {
  AnalysisResultCo1ScorePath,
  AnalysisResult as Co1Result,
  ConvertResult as ConvertCo1Result,
} from "../../engines/co1Engine";
import {
  MeanResult,
  ResultDiagramIndex,
  ResultDiagramParam,
} from "../../types";
import allPhrases from "../../assets/json/phrases.json";
import {
  RenderMainResult,
  RenderUixTable,
  getPhraseNumberByFilename,
} from "./ResultUIX";
import { findMeanByDataPath } from "../../engines/baseAnalysisEngine";

function RenderMainContent({
  result,
  average,
}: Readonly<{
  result: Record<string, number | string>;
  average: MeanResult;
}>): ReactElement {
  const { t } = useTranslation();
  const progressMax = 98; // 100だと目盛と少しずれる
  const co1Score: number | string = result["Co1_score"];
  const co1ScoreAverage = findMeanByDataPath(
    average,
    AnalysisResultCo1ScorePath
  );
  const dispCo1ScoreAverage = co1ScoreAverage
    ? (co1ScoreAverage / 100) * progressMax
    : null;
  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize={{ base: "sm", sm: "xl" }}>
          {t("UixResult.Co1.title")}
        </Text>
        <Text fontSize="lg">{co1Score}</Text>
      </Flex>
      <Flex alignItems="flex-end" mt={1} justifyContent="space-between">
        <Text mt="2" mr="4" whiteSpace="nowrap" fontSize="sm" mb="4">
          {t("UixResult.Co1.co1_score")}
        </Text>
        <VStack
          align="stretch"
          width="85%"
          height="50px"
          style={{ position: "relative" }}
        >
          <Flex justifyContent="space-between" mb="-2">
            <Text fontSize="xs">0</Text>
            <Text fontSize="xs">50</Text>
            <Text fontSize="xs">100</Text>
          </Flex>
          <Progress
            value={typeof co1Score === "number" ? co1Score : 0}
            max={100}
            width={`${progressMax}%`}
          />
          {dispCo1ScoreAverage != null && (
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: `calc(${dispCo1ScoreAverage}%)`,
                transform: "translateX(-50%)",
              }}
            >
              <Text fontSize="sm" color="primary.theme_lv2">
                ▲
              </Text>
            </div>
          )}
        </VStack>
      </Flex>
    </Box>
  );
}

export function RenderResult({
  co1Result,
  co1Average,
}: Readonly<{
  co1Result: Co1Result;
  co1Average: MeanResult;
}>): ReactElement {
  const { t } = useTranslation();

  const convertCo1Result = ConvertCo1Result({ result: co1Result });

  const tableParamColumns: ResultDiagramIndex[] = [
    {
      width: "100%",
      title: t("UixResult.voice_analysis_details"),
      align: "left",
    },
    {
      width: "100px",
      subWidth: "60px",
      title: t("UixResult.voice_analysis_score"),
      align: "center",
    },
  ];

  const tableParamData: ResultDiagramParam[][] = [
    [
      { value: t("UixResult.Co1.speech_speed"), align: "left" },
      { value: convertCo1Result["DSTP_PSA"] },
    ],
    [
      { value: t("UixResult.Co1.speech_rhythm_stability"), align: "left" },
      { value: convertCo1Result["DSTP_PSS"] },
    ],
  ];

  const tablePhrasesColumns: ResultDiagramIndex[] = [
    { width: "32px", title: "No" },
    { width: "100%", title: t("UixResult.voice_content"), align: "left" },
    { width: "100px", subWidth: "60px", title: "sec" },
  ];

  const co1SubParameters: Record<string, object> = co1Result
    ? co1Result.co1_sub_parameters
    : {};
  const tablePhrasesData: ResultDiagramParam[][] = [];
  const filenames = Object.keys(co1SubParameters);
  const sortedFilenames = filenames.sort();
  const uixPhrases = allPhrases["UIX"]["phrases"];

  sortedFilenames.forEach((filename) => {
    const phraseIndex = getPhraseNumberByFilename(filename);
    const uixPhrase = uixPhrases[phraseIndex];
    if ("co1VoiceType" in uixPhrase && uixPhrase.co1VoiceType != "NO_SETTING") {
      const phrase =
        uixPhrases[phraseIndex]["resultPhraseKey"] ??
        uixPhrases[phraseIndex]["phraseKey"];
      const dataList: ResultDiagramParam[] = [
        { value: phraseIndex + 1 },
        { value: t(phrase), align: "left" },
      ];
      if (uixPhrase.co1VoiceType === "REPEAT_PATAKA") {
        dataList.push({ value: convertCo1Result["DSTP_DR"] });
      } else if (uixPhrase.co1VoiceType === "LONG_VOWEL_A") {
        dataList.push({ value: convertCo1Result["ParamD_LVP_DR"] });
      }
      tablePhrasesData.push(dataList);
    }
  });

  return (
    <Stack spacing={2}>
      <RenderMainResult
        imgSrc={co1Image}
        content={createElement(RenderMainContent, {
          result: convertCo1Result,
          average: co1Average,
        })}
      />
      <RenderUixTable
        diagramIndex={tableParamColumns}
        diagramParams={tableParamData}
      />
      <RenderUixTable
        diagramIndex={tablePhrasesColumns}
        diagramParams={tablePhrasesData}
      />
    </Stack>
  );
}
