import { ReactElement, useContext, useEffect, useState } from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { ImageContext } from "../atoms/ImageProvider";
import { useTranslation } from "react-i18next";
import WeatherSunnyImgUrl from "../../assets/images/weather/sunny.png";
import WeatherCloudyImgUrl from "../../assets/images/weather/cloudy.png";
import WeatherRainyImgUrl from "../../assets/images/weather/rainy.png";

export function ResultCo1Demo(props: Readonly<{
  title: string;
  co1_result: string;
}>): ReactElement {
  const { imageSrc, preloadImage } = useContext(ImageContext);
  const [resultComment, setResultComment] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    if (!imageSrc || !imageSrc.WeatherImg[props.co1_result]) {
      preloadImage &&
        preloadImage("WeatherImg", {
          A: WeatherSunnyImgUrl,
          B: WeatherCloudyImgUrl,
          C: WeatherRainyImgUrl,
        });
    }
    switch (props.co1_result) {
      case "A":
        setResultComment(t("Co1DemoResult.resultA_comment"));
        break;
      case "B":
        setResultComment(t("Co1DemoResult.resultB_comment"));
        break;
      case "C":
        setResultComment(t("Co1DemoResult.resultC_comment"));
        break;
    }
  }, [props.co1_result, setResultComment, t, imageSrc, preloadImage]);
  return (
    <Box mb={10} color="#555555">
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Text fontSize="2xl">{resultComment}</Text>
        <Image
          src={imageSrc ? imageSrc.WeatherImg[props.co1_result] : undefined}
          boxSize="80%"
        />
        <Box width="80%">
          <Text fontSize="sm">{t("Co1DemoResult.note1")}</Text>
          <Text fontSize="sm" mt={2}>
            {t("Co1DemoResult.note2")}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
}
