import { Flex, Divider, Box, Text, Heading } from "@chakra-ui/react";
import { ReactElement } from "react";

type Props = {
  title: string;
  description: string;
};

export function HeadingWithVerticalLine(props: Readonly<Props>): ReactElement {
  return (
    <Flex alignItems="center">
      <Divider
        orientation="vertical"
        borderColor="red.200"
        borderWidth={3}
        height="auto"
        minHeight="60px"
        mr={2}
      />
      <Box>
        {/* 大きな見出し */}
        <Heading as="h2" fontSize="3xl" fontWeight="bold">
          {props.title}
        </Heading>
        {/* 説明 */}
        <Text fontSize="sd" fontWeight="bold" color="red.200">
          {props.description}
        </Text>
      </Box>
    </Flex>
  );
}
